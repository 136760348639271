import React from "react";
import styles from './index.module.scss';
import {Box, Typography} from "@mui/material";
import Footer from "../Footer";

const thankYou = () =>
  <Box className={styles.wrapperBox}>
    <Box className={styles.textBox}>
      <Typography
        variant="h3"
        sx={{marginBottom: "2rem"}}
      >
        Grazie per aver compilato il sondaggio!
      </Typography>
    </Box>

    <Box className={styles.footerBox}>
      <Footer/>
    </Box>
  </Box>


export default thankYou;