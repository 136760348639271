import React, {Component} from 'react';

class CookiePolicyScript extends Component {

  componentDidMount() {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.src = "///report.cookie-script.com/r/46ddae4dbc54b554f6e1296d62f99721.js";
    s.charset = "UTF-8";
    s.setAttribute('data-cookiescriptreport', 'report');

    this.instance.appendChild(s);
  }

  render() {
    return (
      <>
        <h1>INFORMATIVA PRIVACY E COOKIE POLICY – FME EDUCATION S.p.A.</h1>
        <h3>
          INFORMATIVA PRIVACY REDATTA AI SENSI DEGLI ARTT. 13 E 14 DEL REGOLAMENTO EU N. 2016/679 PER IL
          TRATTAMENTO DI DATI PERSONALI.
        </h3>
        <h3>1. PREMESSA</h3>
        <p>
          <strong>FME EDUCATION S.p.A.</strong>
          , c.f. e Partita IVA n. 08233380966, REA MI-2011580, con sede legale in
          20122 – Milano(MI), in Viale Majno n. 7 (in seguito anche la
          “
          <strong>Società</strong>
          ”), tel.
          {' '}
          <a href="tel:+3902300761">02/300761</a>
          , email
          {' '}
          <a
            href="mailto:privacy@fmeeducation.it"
            target="_blank"
            rel="noreferrer"
          >
            privacy@fmeeducation.it
          </a>
          ,
          proprietaria del sito web
          {' '}
          <a href="https://myedu.it">https://myedu.it</a>
          , è consapevole dell’importanza
          che riveste un trattamento responsabile dei dati personali di tutti gli internauti. Per questo la
          Società desidera informare e rassicurare circa l’utilizzo ed il trattamento dei dati personali forniti
          da ciascun internauta o comunque acquisiti
          <br/>
          dal Titolare (come verrà successivamente definito) nel corso del rapporto intercorrente ovvero
          dell’interazione con le pagine del sito web
          {' '}
          <a href="https://myedu.it">https://myedu.it</a>
          , nel
          rispetto dei diritti e delle libertà fondamentali dell’individuo, nonché in maniera conforme a quanto
          prescritto dalla normativa in materia di protezione dei dati personali.
          <br/>
          Si raccomanda agli interessati che si connettono al sito web di prendere attenta visione della
          seguente Informativa Privacy, redatta ai sensi degli artt. 13 e 14 del Regolamento UE n. 2016/679 in
          materia di protezione dei dati personali (in seguito anche il “
          <strong>GDPR</strong>
          ” o il
          “
          <strong>Regolamento</strong>
          ”). Si evidenzia che la presente Informativa Privacy e Cookie Policy è
          resa esclusivamente per questo sito web e non anche per altri siti web eventualmente consultabili
          dall’utente tramite link pubblicati sul sito web stesso.
          <br/>
          Ai sensi della normativa vigente in materia di trattamento dei dati personali, il trattamento delle
          informazioni che La riguardano sarà improntato ai principi di correttezza, liceità, necessità,
          minimizzazione, limitazione delle finalità e della conservazione, trasparenza, esattezza, integrità e
          riservatezza, per la garanzia dei Suoi diritti.
          <br/>
          Ai sensi dell’art. 13 del GDPR, Le forniamo quindi le seguenti informazioni.
        </p>
        <h3>2. TITOLARE DEL TRATTAMENTO DEI DATI</h3>
        <p>
          Il titolare del trattamento dei dati personali raccolti dalla Società, da contattare nel caso in cui
          l’utente avesse domande relative alla presente Informativa Privacy o alle modalità e funzioni del
          trattamento dei propri dati personali, è il seguente:
          <br/>
          FME EDUCATION S.p.A., c.f. e Partita IVA n. 08233380966, REA MI-2011580;
          <br/>
          20122 – Milano (MI), Viale Majno n. 7
          <br/>
          Tel.:
          {' '}
          <a href="tel:+3902300761">02/300761</a>
          ;
          <br/>
          Email:
          {' '}
          <a href="mailto:privacy@fmeeducation.it">privacy@fmeeducation.it</a>
          .
          <br/>
          Ai sensi dell’art. 4 GDPR, per “Titolare del trattamento dei dati personali” (in seguito anche solo il
          “
          <strong>Titolare</strong>
          ”) si intende la persona fisica o giuridica, l’autorità pubblica, il
          servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del
          trattamento di dati personali; quando le finalità e i mezzi di tale trattamento sono determinati dal
          diritto dell’Unione o degli Stati membri, il titolare del trattamento o i criteri specifici
          applicabili alla sua designazione possono essere stabiliti dal diritto dell’Unione o degli Stati
          membri.
        </p>
        <h3>3. COOKIE TECNICI E BASE GIURIDICA DEL TRATTAMENTO DEI DATI</h3>
        <p>
          I cookies sono piccoli file di testo inviati all’utente dal sito web visitato. Vengono memorizzati
          sull’hard disk del computer, consentendo in questo modo al sito web di riconoscere gli utenti e
          memorizzare determinate informazioni su di loro, al fine di permettere o migliorare il servizio offerto.
          <br/>
          Esistono diverse tipologie di cookies. Alcuni sono necessari per poter navigare sul sito web, altri
          hanno scopi diversi come garantire la sicurezza interna, amministrare il sistema, effettuare analisi
          statistiche, comprendere quali sono le sezioni del sito che interessano maggiormente gli utenti o
          offrire una visita personalizzata del sito.
          <br/>
          Il sito web utilizza solo cookies tecnici e non di profilazione. Quanto precede si riferisce sia al
          computer dell’utente sia ad ogni altro dispositivo che l’utente può utilizzare per connettersi al
          sito.
          <br/>
          I cookies tecnici sono quelli utilizzati al solo fine di effettuare la trasmissione di una
          comunicazione su una rete di comunicazione elettronica, o nella misura strettamente necessaria al
          fornitore di un servizio della società
          <br/>
          dell’informazione esplicitamente richiesto dall’abbonato o dall’utente a erogare tale servizio.
          <br/>
          Essi non vengono utilizzati per scopi ulteriori e sono normalmente installati direttamente dal
          titolare o gestore del sito web.
          <br/>
          I cookies tecnici possono essere suddivisi nelle seguenti tipologie:
          <br/>
          – cookies di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del sito
          web (permettendo, ad esempio, di realizzare un acquisto o autenticarsi per accedere ad aree
          riservate);
          <br/>
          – cookies analytics, assimilati ai cookies tecnici laddove utilizzati direttamente dal gestore del
          sito per raccogliere informazioni, in forma aggregata, sul numero degli utenti e su come questi
          visitano il sito stesso;
          <br/>
          – cookies di funzionalità, che permettono all’utente la navigazione in funzione di una serie di
          criteri selezionati (ad esempio, la lingua o i prodotti selezionati per l’acquisto) al fine di
          migliorare il servizio reso allo stesso.
          <br/>
          La disabilitazione dei cookies potrebbe limitare la possibilità di usare il sito web e impedire di
          beneficiare appieno delle funzionalità e dei servizi presenti sul sito. Per decidere quali accettare e
          quali rifiutare, è illustrata di seguito una descrizione dei cookies utilizzati sul sito web.
          <br/>
          Il trattamento di dati personali per questa finalità è legittimato dall’art. 6, par. 1, lett. b),
          GDPR, ovvero per consentire all’utente di fruire del servizio richiesto.
        </p>
        <h3>4. TIPOLOGIE DI COOKIE UTILIZZATI</h3>
        <p>
          <strong>Cookies di prima parte:</strong>
          <br/>
          I cookies di prima parte (ovvero i cookies che appartengono al sito web dell’editore che li ha creati)
          sono impostati dal sito web visitato dall’utente, il cui indirizzo compare nella finestra URL.
          L’utilizzo di tali cookies ci permette di far funzionare il sito web in modo efficiente e di tracciare i
          modelli di comportamento dei visitatori.
          <br/>
          <strong>Cookies di terze parti:</strong>
          <br/>
          I cookies di terzi sono impostati da un dominio differente da quello visitato dall’utente. Se un utente
          visita un sito e una società diversa invia l’informazione sfruttando quel sito, si è in presenza di
          cookies di terze parti.
          <br/>
          <strong>Cookies di sessione:</strong>
          <br/>
          I cookies di sessione sono memorizzati temporaneamente e vengono cancellati quando l’utente chiude il
          browser. Se l’utente si registra al sito web, il sito può utilizzare cookies che raccolgono dati
          personali al fine di identificare l’utente in occasione di visite successive e di facilitare l’accesso –
          login al sito (per esempio conservando username e password dell’utente) e la navigazione sullo stesso.
          Inoltre, il Titolare utilizza i cookies per finalità di amministrazione del sistema.
          <br/>
          Il sito web potrebbe contenere link ad altri siti. Il Titolare non ha alcun accesso o controllo su
          cookies, web bacon e altre tecnologie di tracciamento usati sui siti web di terzi cui l’utente può
          accedere dal sito, sulla disponibilità, su qualsiasi contenuto e materiale che è pubblicato od ottenuto
          attraverso tali siti e sulle relative modalità di trattamento dei dati personali. Il Titolare, a questo
          proposito, considerata la mole di tali siti terzi, declina espressamente ogni relativa responsabilità.
          L’utente dovrebbe verificare la privacy policy dei siti web di terze parti cui accede dal sito per
          conoscere le condizioni applicabili al trattamento dei dati personali poiché
          <br/>
          la presente privacy policy si applica solo al sito web del Titolare.
          <br/>
          <strong>Cookies persistenti:</strong>
          <br/>
          I cookies persistenti sono memorizzati sul dispositivo dell’utente tra le sessioni del browser,
          consentendo di ricordare le preferenze o le azioni dell’utente in un determinato sito web. Possono
          essere utilizzati per diversi scopi, come ad esempio per ricordare le preferenze e le scelte quando si
          utilizza il sito web.
          <br/>
          <strong>Cookies essenziali:</strong>
          <br/>
          Questi cookies sono strettamente necessari per il funzionamento del sito web. Senza l’uso di tali
          cookies alcune parti del sito web non funzionerebbero. Comprendono, ad esempio, i cookies che consentono
          di accedere in aree protette del sito web. Questi cookies non raccolgono informazioni per scopi di
          marketing e non possono essere disattivati.
          <br/>
          <strong>Cookies funzionali:</strong>
          <br/>
          Questi cookies servono a riconoscere un utente che torna a visitare il sito web. Permettono di
          personalizzare i contenuti e ricordare le preferenze (ad esempio, la lingua selezionata o la regione).
          Questi cookies non raccolgono informazioni che possono identificare l’utente. Tutte le informazioni
          raccolte sono anonime.
          <br/>
          <strong>Cookies di condivisione sui Social Network:</strong>
          <br/>
          Questi cookies facilitano la condivisione dei contenuti del sito web attraverso social network quali
          Facebook e Twitter. Per prendere visione delle rispettive privacy e cookies policies è possibile
          visitare i siti web dei social networks. Nel caso di Facebook e Twitter, l’utente può prendere visione
          delle policies ai seguenti link:
          <br/>
          <a
            href="https://www.facebook.com/privacy/policies/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.facebook.com/privacy/policies/cookies/
          </a>
          &nbsp;e
          <a
            href="https://twitter.com/it/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://twitter.com/it/privacy
          </a>
          .
          <br/>
          Nello specifico, si riporta di seguito la lista dei principali cookies utilizzati sul sito web e
          relative descrizioni e funzionalità, compresa la durata temporale:
          <br/>


        </p>

        <div
          ref={el => (this.instance = el)}
          style={{padding: "32px 0"}}
        />

        <h3>5. COME MODIFICARE LE IMPOSTAZIONI SUI COOKIES</h3>
        <p>
          La maggior parte dei browser accetta automaticamente i cookies, ma l’utente normalmente può modificare
          le impostazioni per disabilitare tale funzione. È possibile bloccare tutte le tipologie di cookies,
          oppure accettare di riceverne soltanto alcuni e disabilitarne altri. La sezione “Opzioni” o “Preferenze”
          nel menu del browser permette di evitare di ricevere cookies e altre tecnologie di tracciamento utente,
          e come ottenere notifica dal browser dell’attivazione di queste tecnologie. In alternativa, è anche
          possibile consultare la sezione “Aiuto”
          <br/>
          della barra degli strumenti presente nella maggior parte dei browser.
          <br/>
          È anche possibile selezionare il browser utilizzato dalla lista di seguito e seguire le
          istruzioni:
          <br/>
          –
          {' '}
          <a
            href="https://support.microsoft.com/it-it/topic/visualizzare-i-cookie-in-microsoft-edge-a7d95376-f2cd-8e4a-25dc-1de753474879"
            target="_blank"
            rel="noreferrer"
          >
            Microsoft Edge
          </a>
          ;
          <br/>
          –
          {' '}
          <a
            href="https://support.google.com/chrome/answer/95647?hl=en&amp;co=GENIE.Platform%3DDesktop"
            target="_blank"
            rel="noreferrer"
          >
            Google Chrome
          </a>
          ;
          <br/>
          –
          {' '}
          <a
            href="https://support.apple.com/it-it/guide/deployment/depf7d5714d4/web"
            target="_blank"
            rel="noreferrer"
          >
            Safari
          </a>
          ;
          <br/>
          –
          {' '}
          <a
            href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie"
            target="_blank"
            rel="noreferrer"
          >
            Mozilla
            Firefox
          </a>
          ;
          <br/>
          –
          {' '}
          <a
            href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
            target="_blank"
            rel="noreferrer"
          >
            Opera
          </a>
          .
          <br/>
          Da dispositivo mobile:
          <br/>
          –
          {' '}
          <a
            href="https://support.google.com/accounts/answer/61416?hl=it&amp;co=GENIE.Platform%3DAndroid"
            target="_blank"
            rel="noreferrer"
          >
            Android
          </a>
          ;
          <br/>
          –
          {' '}
          <a
            href="https://support.apple.com/it-it/guide/deployment/depf7d5714d4/web"
            target="_blank"
            rel="noreferrer"
          >
            Safari.
          </a>
          <br/>
          Per maggiori informazioni sui cookies e per gestire le preferenze sui cookies (di prima e/o terza
          parte) si invitano gli utenti a visitare anche la piattaforma
          {' '}
          <a
            href="https://www.youronlinechoices.com"
            target="_blank"
            rel="noreferrer"
          >
            www.youronlinechoices.com
          </a>
          .
          <br/>
          Si ricorda però che la disabilitazione dei cookie di navigazione o quelli funzionali può causare il
          malfunzionamento del sito web e/o limitare il servizio offerto dalla Società.
        </p>
        <h3>6. NATURA DELLA RACCOLTA DEI DATI E CONSEGUENZE DI UN EVENTUALE MANCATO CONFERIMENTO</h3>
        <p>
          Il conferimento dei dati di navigazione è obbligatorio al fine di fornire il servizio di navigazione
          sul sito web, con la precisazione che il mancato, parziale o inesatto conferimento dei dati personali,
          in quanto necessari per l’esecuzione della prestazione richiesta, non rende possibile tale esecuzione,
          mentre il mancato, parziale o inesatto conferimento dei dati personali facoltativi non necessari non
          comporta alcuna conseguenza.
          <br/>
          L’installazione dei cookie tecnici, analitici e di terze parti è facoltativa (può essere impedita
          tramite le impostazioni del browser), ma potrebbe comportare l’inutilizzabilità del sito web o di
          alcuni dei suoi servizi.
        </p>
        <h3>7. MODALITÀ DI TRATTAMENTO E PERIODO DI CONSERVAZIONE</h3>
        <p>
          I dati personali degli internauti saranno trattati in forma elettronica e/o cartacea, nel rispetto dei
          principi stabiliti dalla normativa di protezione dei dati personali. Il trattamento avverrà previa
          adozione di ogni misura di sicurezza, tecnica ed organizzativa, adeguata ai rischi per i diritti e le
          libertà fondamentali dell’Interessato. I dati sono trattati presso le sedi operative del Titolare ed in
          ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. I dati personali
          dell’Interessato potrebbero essere trasferiti in un paese diverso da quello in cui l’Interessato si
          trova.
          <br/>
          Le operazioni di trattamento saranno svolte da soggetti autorizzati dalla Società e dal Titolare ad
          accedere alle informazioni, i quali saranno adeguatamente istruiti in merito alla protezione dei dati
          personali e designati quali incaricati del trattamento.
          <br/>
          I dati personali raccolti saranno conservati per tutto il periodo di erogazione del servizio e per un
          periodo di tempo non superiore a quello necessario al conseguimento delle finalità per le quali sono
          raccolti e saranno cancellati nel termine massimo di n. 15 giorni dall’esaurimento di tali attività. I
          dati raccolti attraverso i servizi riconducibili a Google (ad esempio Google AdWords) saranno
          conservati per un periodo di 14 mesi.
        </p>
        <h3>8. COMUNICAZIONE E DIFFUSIONE DEI DATI</h3>
        <p>
          I Suoi dati personali, raccolti per le finalità indicate al punto 3), nonché per esigenze tecniche,
          operative, pratiche, di mercato, di pubblicizzazione e promozione, strettamente connesse all’attività
          della Società, potranno essere comunicati a terze parti, e in particolare:
          <br/>
          – a tutte le persone fisiche e giuridiche, o società partner o collegate alla Società, operanti nel
          medesimo settore merceologico, nei casi in cui la comunicazione risulti necessaria per le finalità
          sopra illustrate;
          <br/>
          – per quanto concerne il servizio di invio di mailing list, a società esterne, qualora la Società si
          avvalga di piattaforme esterne per tale attività;
          <br/>
          – ai collaboratori e dipendenti appositamente designati quali incaricati del trattamento e nell’ambito
          delle relative mansioni, compreso il servizio di help desk, che opereranno sotto la diretta
          autorizzazione del Titolare e che riceveranno a riguardo adeguate istruzioni operative e di sicurezza;
          <br/>
          – alle pubbliche autorità e amministrazioni dello Stato per finalità connesse all’adempimento degli
          obblighi di legge, per motivi di indagine, di accertamento e di repressione di illeciti
          amministrativi, fiscali, contabili, tributari e penali;
          <br/>
          – a società che offrono servizi informatici e/o di sviluppo e manutenzione software e del sito web e
          di aggiornamento e revisione degli strumenti informatici.
          <br/>
          Il loro elenco è costantemente aggiornato e può essere reperito agevolmente e gratuitamente inviando
          una comunicazione scritta al Titolare del trattamento all’indirizzo sopra indicato o una email al
          seguente indirizzo:
          <br/>
          <a
            href="mailto:privacy@fmeeducation.it"
            target="_blank"
            rel="noreferrer"
          >
            privacy@fmeeducation.it
          </a>
          .
          <br/>
          I dati non personali degli utenti potranno essere forniti a soggetti terzi in diretto contatto con la
          Società, con lo scopo di monitorare e migliorare le prestazioni della piattaforma.
          <br/>
          I dati personali potranno inoltre essere conosciuti, in relazione allo svolgimento delle mansioni
          assegnate, dal personale della Società, ivi compresi gli stagisti, i lavoratori interinali, i
          consulenti, i dipendenti di società esterne al Titolare, tutti appositamente nominati incaricati del
          trattamento.
        </p>
        <h3>9. TRASFERIMENTO DI DATI AL DI FUORI DELL’UNIONE EUROPEA</h3>
        <p>
          La Società non trasferisce i dati personali degli interessati, raccolti attraverso il sito
          <a
            href="https://myedu.it"
          >
            https://myedu.it
          </a>
          {' '}
          e gli strumenti ivi previsti, in paesi situati fuori
          dall’Unione Europea.
        </p>
        <h3>10. COLLEGAMENTO A SITI O SERVIZI DI SOGGETTI TERZI</h3>
        <p>
          La presente informativa è resa solo per i trattamenti di dati personali effettuati attraverso il
          presente sito web o gli strumenti previsti dallo stesso, e non anche per altri siti web eventualmente
          consultati dall’utente tramite link, i cui gestori operano in qualità di titolari autonomi del
          trattamento. Si invitano pertanto gli utenti a leggere con attenzione, prima di accedere a servizi di
          soggetti terzi, le loro informative privacy.
        </p>
        <h3>11. DIRITTI DELL’INTERESSATO</h3>
        <p>
          Gli articoli 12, 15, 16, 17, 18, 20 e 34 del Regolamento conferiscono agli Interessati la possibilità
          di esercitare specifici diritti.
          <br/>
          L’Interessato potrà in ogni momento esercitare i Suoi diritti nei confronti del Titolare o del
          responsabile del trattamento.
          <br/>
          In particolare, l’Interessato può ottenere:
          <br/>
          a) conferma dell’esistenza o meno di dati personali che lo riguardano, anche se non ancora registrati,
          e la loro comunicazione in forma intelligibile;
          <br/>
          b) informazioni sulle finalità e modalità del trattamento;
          <br/>
          c) informazioni sulle categorie di dati personali in questione;
          <br/>
          d) gli estremi identificativi del titolare e, se designato, del responsabile del trattamento
          <br/>
          e) i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati,
          in particolare se destinatari di Paesi terzi o organizzazioni internazionali;
          <br/>
          f) quando è possibile, il periodo di conservazione dei dati personali previsto, oppure, se non è
          possibile, i criteri utilizzati per determinare tale periodo;
          <br/>
          g) l’aggiornamento, la rettificazione ovvero, quando vi ha interesse, l’integrazione e la limitazione
          dei dati personali;
          <br/>
          h) la cancellazione, la trasformazione in forma anonima o il blocco dei dati trattati in violazione di
          legge o per adempiere ad un obbligo legale previsto dal diritto dell’Unione Europea o dal diritto
          italiano, compresi quelli di cui non è necessaria la conservazione in relazione agli scopi per i quali
          i dati sono stati raccolti o successivamente trattati (cd. diritto all’oblio);
          <br/>
          i) la revoca del consenso prestato, quando questo si basi sull’ipotesi prevista dall’art. 6, par. 1,
          lett. a. (quando “l’interessato ha espresso il consenso al trattamento dei propri dati personali per
          una o più specifiche finalità”), oppure dall’art. 9, par. 2, lett. a. (quando “l’interessato ha
          prestato il proprio consenso esplicito al trattamento di tali dati personali per una o più finalità
          specifiche”) del GDPR, in qualsiasi momento senza pregiudicare la liceità del trattamento basata sul
          consenso prestato prima della revoca;
          <br/>
          j) la limitazione del trattamento, nelle ipotesi previste dal Regolamento;
          <br/>
          k) la comunicazione dell’eventuale violazione dei dati personali, quando questa sia suscettibile di
          presentare un elevato rischio per i diritti e le libertà delle persone fisiche, salvo che il Titolare
          abbia messo in atto misure tecniche e organizzative per rendere i dati incomprensibili a chiunque non
          fosse autorizzato ad accedervi, oppure abbia successivamente adottato misure atte a scongiurare il
          sopraggiungere di tale elevato rischio, ovvero detta comunicazione richiederebbe sforzi
          sproporzionati;
          <br/>
          l) la ricezione, in un formato strutturato, di uso comune e leggibile da dispositivo automatico, dei
          dati personali forniti al Titolare, nonché la trasmissione degli stessi a un altro titolare del
          trattamento, e ciò in qualsiasi momento, anche alla cessazione dei rapporti eventualmente intrattenuti
          col Titolare, e, se tecnicamente fattibile, di ottenerne la trasmissione diretta, a condizione che sia
          stato richiesto il consenso.
          <br/>
          L’Interessato ha altresì diritto di opporsi in qualsiasi momento, in tutto o in parte, ferme restando
          le possibili conseguenze di cui all’art. 2, c. 1, del Regolamento:
          <br/>
          – per motivi legittimi al trattamento dei dati personali che lo riguardano, ancorché pertinenti allo
          scopo della raccolta;
          <br/>
          – al trattamento dei dati personali che lo riguardano svolto ai sensi dell’art. 6, par. 1, lett. e.
          (“il trattamento è necessario per l’esecuzione di un compito di interesse pubblico o connesso
          all’esercizio di pubblici poteri di cui è investito il titolare del trattamento”) o lett. f. (“il
          trattamento è necessario per il perseguimento del legittimo interesse del titolare del trattamento o
          di terzi”) del GDPR, compresa la profilazione sulla base di tali disposizioni;
          <br/>
          – al trattamento dei dati personali che lo riguardano a fini di invio di materiale pubblicitario o di
          vendita diretta o per il compimento di ricerche di mercato o di comunicazione commerciale (marketing
          diretto), compresa la profilazione nella misura in cui sia ad esso connessa.
          <br/>
          L’Interessato, nel caso in cui ritenga che il trattamento che lo riguarda violi la normativa in
          vigore, ha il diritto di proporre reclamo a un’autorità di controllo, segnatamente a quella dello
          Stato membro in cui risiede abitualmente, lavora oppure del luogo ove si è verificata la presunta
          violazione. L’Autorità di controllo italiana può essere raggiunta ai recapiti presenti sul proprio
          sito web.
          <br/>
          I diritti di cui all’art. 8 GDPR sono esercitati con richiesta rivolta senza formalità al Titolare o
          al responsabile del trattamento, anche per il tramite di un incaricato, alla quale è fornito idoneo
          riscontro senza ingiustificato ritardo e comunque entro un mese, prorogabile di un mese, dal
          ricevimento della richiesta.
          <br/>
          La richiesta rivolta al Titolare può essere trasmessa anche mediante invio di raccomandata alla sede
          della Società o all’indirizzo di posta elettronica:
          {' '}
          <a
            href="mailto:privacy@fmeeducation.it"
            target="_blank"
            rel="noreferrer"
          >
            privacy@fmeeducation.it
          </a>
          <br/>
          Rammentiamo, inoltre, che, qualora il trattamento dei dati dell’utente si basi sul consenso, l’utente
          avrà sempre il diritto di revocarlo: ricordiamo, peraltro, che la revoca non invalida il trattamento
          fino ad allora svolto dalla Società e/o dal Titolare, e che essa potrebbe impedire alla Società di
          perseguire le finalità richieste dall’utente.
        </p>
        <h3>12. MODIFICHE ALLE PRESENTI POLITICHE SULLA PRIVACY</h3>
        <p>
          Il Titolare del trattamento si riserva il diritto di apportare modifiche alla presente informativa
          adeguandola alle eventuali normative, organizzative e tecnologiche. In caso di modifica, la nuova
          versione della stessa sarà pubblicata sui siti internet facenti capo alla Società e, dunque, l’utente
          viene invitato a monitorare periodicamente le informazioni ivi contenute.
        </p>
      </>
    )
      ;
  }
}

export default CookiePolicyScript;
