import './App.scss';
import React, {useState, useEffect} from "react";
import {Controller, useForm} from "react-hook-form";
import {QUESTIONS} from "./questions";
import {useGoogleReCaptcha} from 'react-google-recaptcha-v3';
import TextEditor from "./components/TextEditor";
import Footer from "./components/Footer";
import CookiePolicy from "./components/Policies/Cookie";
import ThankYou from "./components/ThankYou";
import Rating from "./components/Rating";
import {CircularProgress, Typography, Box, Stack, Snackbar, Alert, Button} from "@mui/material";

// const allowedPathnames = [
//   "/",
//   "/cookie-policy",
//   "/privacy-policy",
// ]

const App = () => {
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState(null);
  const [token, setToken] = useState(null);
  const [tokenVerified, setTokenVerified] = useState(null);
  const [feedbackSent, setFeedbackSent] = useState(false)
  const [snackbarData, setSnackbarData] = useState({
    open: false,
    severity: "",
    message: "",
  })

  const {executeRecaptcha} = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: {
      errors
    },
    control,
  } = useForm();

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const tokenParam = params.get("token") // returns null if there is no param

    if (!tokenParam && tokenVerified === null) {
      setToken(null);
      setTokenVerified(false);
    }

    if (tokenParam !== token) {
      setToken(tokenParam);
    }

    if (token && tokenVerified === null) {
      verifyToken()
    }
  }, [token])

  const verifyToken = () => {
    fetch(process.env.REACT_APP_VERIFY_TOKEN_FEEDBACK_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token: token,
      })
    })
      .then(response => response.json())
      .then(response => {
        const {result = false} = response || {};
        setTokenVerified(result);
        setLoading(false)
      })
      .catch(error => {
        console.log(error);
        setTokenVerified(false);
        setLoading(false)
      })
  }

  const getFormError = error => {
    const {type: errorType = null} = error || {};
    switch (errorType) {
      case "required":
        return "Inserisci una risposta";
      case "maxLength":
        return "La risposta può avere un massimo di 2000 caratteri";
      case "minLength":
        return "La risposta deve avere un minimo di 2 caratteri";
      default:
        return "";
    }
  }

  const onSubmit = async data => {
    setLoading(true)

    if (!executeRecaptcha) {
      setSnackbarData({
        open: true,
        severity: "error",
        message: "Si è verificato un errore nella verifica del Recaptcha!",
      })
      setLoading(false);
      return;
    }

    const recaptchaToken = await executeRecaptcha("submit");
    const feedbackData = QUESTIONS.map(question => {
      const {n = 1} = question || {};
      const {
        [`question_${n}`]: answer = ""
      } = data || {}

      return {
        ...question,
        answer,
      }
    })

    feedbackData.push({
      rating: rating / 5,
      max: 1
    });

    addFeedback(feedbackData, recaptchaToken);
  }

  const addFeedback = (feedbackData, recaptchaToken) => {
    setFeedbackSent(true);
    fetch(process.env.REACT_APP_ADD_FEEDBACK_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        feedback_data: {
          ...feedbackData,
        },
        token: token,
        recaptcha_token: recaptchaToken,
      })
    })
      .then(response => {
        const {status = null} = response || {};
        switch (status) {
          case 200:
            setSnackbarData({
              open: true,
              severity: "success",
              message: "La vostra recensione è stata inoltrata con successo"
            })
            setTokenVerified(false);
            window.location.href = "/grazie"
            break;
          case 400:
            setSnackbarData({
              open: true,
              severity: "warning",
              message: "Il link è corrotto o la vostra recension è già stata inoltrata"
            })
            setTokenVerified(false);
            setFeedbackSent(false);
            break;
          case 500:
          case 502:
          case null:
          default:
            setSnackbarData({
              open: true,
              severity: "error",
              message: "Si è verificato un errore, si consiglia di riprovare più tardi"
            })
            setFeedbackSent(false);
            break;
        }
        setLoading(false)
      })
      .catch(error => {
        console.log(error);
        setLoading(false);
        setFeedbackSent(false);
      })
  }

  const closeSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarData({
      open: false,
      severity: "",
      message: "",
    })
  }

  const getPageContent = () => {
    const pathname = window.location.pathname;

    // Force redirect when pathname is not allowed
    // if (!allowedPathnames.includes(pathname)) {
    //   window.location.replace("/");
    // }

    switch (pathname) {
      // Return cookie-policy page
      case "/grazie":
      case "/grazie/":
        return <ThankYou />
      case "/cookie-policy":
      case "/cookie-policy/":
        return <CookiePolicy/>
      // Return privacy-policy page
      // Return feedback form or "feedback already sent" message
      case "/":
      default:
        const {
          open: snackbarOpen = false,
          severity = null,
          message = "",
        } = snackbarData || {};

        if (feedbackSent) {
          return
        }

        return (
          <React.Fragment>
            {
              snackbarOpen &&
              <Snackbar
                open={snackbarOpen}
                onClose={closeSnackbar}
                autoHideDuration={5000}
                anchorOrigin={{vertical: "top", horizontal: "right"}}
              >
                <Alert
                  variant="filled"
                  onClose={closeSnackbar}
                  severity={severity}
                  sx={{width: '100%'}}
                >
                  {message}
                </Alert>
              </Snackbar>
            }
            {
              tokenVerified ?
                <React.Fragment>
                  <div className="appWrapper">
                    <Typography
                      variant="h5"
                      paragraph
                      className="formTitle"
                    >
                      Buongiorno, Le chiediamo di rispondere brevemente a queste poche domande: sembra uno slogan
                      abusato,
                      ma davvero vorremmo conoscere la sua opinione in modo da poterci migliorare e meglio rispondere
                      alle
                      sue necessità.
                    </Typography>
                    <form
                      className="formWrapper"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      {
                        QUESTIONS.map(q => {
                          const {
                            question = "",
                            required = false,
                            n = 0
                          } = q || {};

                          const {
                            [`question_${n}`]: error = null,
                          } = errors || {};

                          return (
                            <div key={`question_${n}`}>
                              <label htmlFor={`question_${n}`} className="questionLabel">
                                <b>{n}. </b>
                                {question}
                              </label>
                              <Controller
                                render={({field}) => <TextEditor {...field} />}
                                name={`question_${n}`}
                                control={control}
                                defaultValue=""
                                {...register(`question_${n}`, {required: required, minLength: 10, maxLength: 2000})}
                              />
                              <span className="errorsWrapper">
                                {getFormError(error)}
                              </span>
                            </div>
                          )
                        })
                      }
                      <label htmlFor="rating" className="questionLabel">
                        <b>6. </b>
                        Dai un voto generale al nostro seminario
                      </label>
                      <Rating
                        name="rating"
                        rating={rating}
                        setRating={setRating}
                      />
                      <Button
                        className="submit-btn"
                        type="submit"
                      >
                        SALVA
                      </Button>
                    </form>
                  </div>
                </React.Fragment> :
                <Box
                  sx={{
                    width: 'calc(100% - 48px)',
                    height: "calc(100vh - 400px)",
                    padding: "16px 24px",
                    display: 'flex',
                    textAlign: 'center',
                    alignItems: 'center',
                    flexDirection: "column",
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    paragraph
                    variant="h5"
                    gutterBottom
                  >
                    Il sondaggio è già stato inoltrato o il link è corrotto
                    <br/>
                    Ti preghiamo di contattare l'assistenza
                  </Typography>
                  <Typography
                    paragraph
                    variant="body1"
                  >
                    Il servizio di assistenza è gratuito e disponibile
                    <br/>
                    dalle 10.00 alle 13.00 e dalle 14.00 alle 19.00
                    <br/>
                    nei giorni feriali contattando i numeri:
                    <br/>
                    <a
                      href="tel:+390230076303"
                    >
                      02 30076303
                    </a>
                    <br/>
                    <a
                      href="tel:+393278882745"
                    >
                      327 8882745
                    </a>
                    &nbsp;
                    <a
                      href="https://api.whatsapp.com/send?phone=00393278882745"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      (assistenza whatsapp)
                    </a>
                    <br/>
                    oppure scrivendo a:
                    <br/>
                    <a
                      href='mailto:scuole@myedu.it'
                    >
                      scuole@myedu.it
                    </a>
                  </Typography>
                </Box>
            }
            <Footer/>
          </React.Fragment>
        );
    }
  }

  // Return loader
  if (tokenVerified === null || feedbackSent || loading) {
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          textAlign: 'center',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Stack sx={{color: '#F69D1B'}} direction="row">
          <CircularProgress color="inherit"/>
        </Stack>
      </Box>
    )
  } else return getPageContent()
}

export default App;
