export const QUESTIONS = [
  {
    question: "Il formatore è stato sufficientemente chiaro ed esaustivo nella presentazione?",
    required: true,
    n: 1,
  },
  {
    question: "Sarebbe interessata ad altri incontri di formazione personalizzati?",
    required: true,
    n: 2,
  },
  {
    question: "Quale aspetto del progetto MyEdu l'ha colpita di più?",
    required: true,
    n: 3,
  },
  {
    question: "Pensa di utilizzare MyEdu come supporto o integrazione delle sue lezioni?",
    required: true,
    n: 4,
  },
  {
    question: "Se avesse l’opportunità, consiglierebbe MyEdu a colleghe/i di altre scuole?",
    required: true,
    n: 5,
  },
]