import React from "react";
import {Grid} from "@mui/material";
import {styled} from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import styles from './index.module.scss';
import LogoWhite from '../../assets/images/logotype_white.png'

const Item = styled(Paper)(({theme}) => ({
  backgroundColor: "#F69D1B",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: "#fff",
  boxShadow: "none"
}));

const Footer = () => (
  <Box sx={{flexGrow: 1}}>
    <Grid
      container
      spacing={2}
      direction="row"
      alignItems="stretch"
      justifyContent="space-around"
      className={styles.footerMainRow}
    >
      {/*==== Col 1 ====*/}
      <Grid
        item
        className={styles.footerCol}
        xs={12}
        md={4}
      >
        <Item>
          <p className={styles.footerColTitle}>
            Contatti
          </p>
          <a
            href="https://myedu.it/"
            title="MyEDU"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className={styles.footerLogo}
              src={LogoWhite}
              alt="MyEdu"
            />
          </a>
          <p>
            Viale Majno, 7
            20122 Milano
          </p>
          <a
            href="https://myedu.it/lavora-con-noi/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lavora con noi
          </a>
        </Item>
      </Grid>

      {/*==== Col 2 ====*/}
      <Grid
        item
        className={styles.footerCol}
        xs={12}
        md={4}
      >
        <Item>
          <p className={styles.footerColTitle}>
            Assistenza
          </p>

          <p>
            Il servizio di assistenza è gratuito e disponibile dalle 10.00 alle 13.00
            e dalle 14.00 alle 19.00 nei giorni feriali contattando i numeri:
          </p>

          <a href="tel:00390230076303">
            02 30076303
          </a>

          <br/>

          <a
            href="https://api.whatsapp.com/send?phone=00393278882745"
            target="_blank"
            rel="noopener noreferrer"
          >
            327 8882745
          </a>
          <span> (assistenza WhatsApp)</span>

          <p>
            Oppure scrivendo a:
          </p>
          <a
            className={styles.footerLink}
            href="mailto:team@myedu.it"
          >
            team@myedu.it
          </a>
        </Item>
      </Grid>

      {/*==== Col 3 ====*/}
      <Grid
        item
        className={styles.footerCol}
        xs={12}
        md={4}
      >
        <Item>
          <p className={styles.footerColTitle}>
            Assistenza remota
          </p>

          <p>
            Per poter usufruire dei servizi di assistenza remota è necessario scaricare:
          </p>

          <a
            download
            target="_blank"
            rel="noreferrer"
            href="https://public.files.myedu.it/download/teamviewer/TeamViewerQS_it.exe"
          >
            TeamViewer remote client (Win)
          </a>
          <br/>
          <a
            download
            target="_blank"
            rel="noreferrer"
            href="https://public.files.myedu.it/download/teamviewer/TeamViewerQS.zip"
          >
            TeamViewer remote client (Mac)
          </a>
          <br/>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://play.google.com/store/apps/details?id=com.teamviewer.quicksupport.market&hl=it"
          >
            TeamViewer remote client (Android)
          </a>
        </Item>
      </Grid>

      {/*==== Col 4 ====*/}
      <Grid
        item
        className={styles.footerLastCol}
        xs={12}
      >
        <Item>
          <p>
            ©{new Date().getFullYear()} FME Education S.p.A. - Tutti i diritti riservati | P.I. 08233380966 |
            <a
              target="_blank"
              rel="noreferrer"
              href="https://myedu.it/privacy-policy"
            >
              Privacy Policy
            </a>
            |
            <a
              target="_blank"
              rel="noreferrer"
              href="/cookie-policy"
            >
              Cookie Policy
            </a>
          </p>
        </Item>
      </Grid>
    </Grid>
  </Box>
)

export default Footer;