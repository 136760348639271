import React from "react";
import {Box, Rating as MuiRating} from "@mui/material";
import {styled} from "@mui/material/styles";

const Rating = ({name, rating, setRating}) => {
  const StyledRating = styled(MuiRating)({
    '& .MuiRating-iconFilled': {
      fontSize: "24px",
      color: '#F69D1B',
    },
    '& .MuiRating-iconHover': {
      fontSize: "24px",
      color: '#f5b251',
    },
    '& .MuiRating-iconEmpty': {
      fontSize: "24px",
      color: '#f5b251',
    },
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "35px",
        border: "1px solid #F69D1B",
        padding: "12px 24px",
      }}
    >
      <StyledRating
        name={name}
        value={rating}
        onChange={(event, newValue) => {
          setRating(newValue);
        }}
        precision={0.5}
      />
      {rating}
    </Box>
  )
}

export default Rating;