import {Component} from "react";
import Footer from "../Footer";
import styles from "./Privacy.module.scss";
import CookiePolicyScript from "../CookiePolicyScript";

class CookiePolicy extends Component {
    render() {
        return (
            <div className={styles.pageWrapper}>
                <div className={styles.policyWrapper}>
                    <CookiePolicyScript />
                </div>
                <Footer/>
            </div>
        )
    }
}

export default CookiePolicy;