import React, {useEffect, useState} from "react";
import {ContentState, convertToRaw, EditorState} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {Editor} from "react-draft-wysiwyg";
import styles from "./index.module.scss";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const TextEditor = ({onChange, value}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (!updated) {
      const defaultValue = value ? value : "";
      const blocksFromHtml = htmlToDraft(defaultValue);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      );
      const newEditorState = EditorState.createWithContent(contentState);
      setEditorState(newEditorState);
    }
  }, [value]);

  const onEditorStateChange = (editorState) => {
    setUpdated(true);
    setEditorState(editorState);

    return onChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };


  return (
    <React.Fragment>
      <Editor
        spellCheck
        editorState={editorState}
        editorClassName={styles.editorBody}
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            'inline',
            'history'
          ]
        }}
      />
    </React.Fragment>
  );
};

export default TextEditor;
